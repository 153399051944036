import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import {
  GroupAdd,
  PersonAdd,
  Drafts as DraftsIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import DashboardNewStats from '../components/DashboardNewStats';

const useStyles = styled({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  customIcon: {
    height: '100%',
    width: '100%',
  },
});

function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();

  const routeToPage = (e, page) => {
    e.stopPropagation();
    navigate(`/${page}`);
  };

  const permissionss = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );
  const country = useSelector((state) =>
    typeof state.app.selectedCountry !== typeof undefined
      ? state.app.selectedCountry
      : null
  );

  useEffect(() => {
    // console.log('selectedCountry', country);
  }, [country]);

  return (
    <div>
      {permissionss.OPERATIONAL_STATS.READ ? (
        <div className="container-fluid">
          <DashboardNewStats />
        </div>
      ) : (
        <div className="col-sm-12">
          <div>
            <h2>Dashboard/Quick Links</h2>
          </div>
          <div style={{ padding: 30 }}>
            <Grid container spacing={10} justify="center">
              <Card
                style={{
                  display: permissionss.DEALER_PROFILES.READ ? 'block' : 'none',
                  margin: 10,
                  width: 300,
                }}
                onClick={(e) => {
                  routeToPage(e, 'dealer-portal');
                }}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    title="Contemplative Reptile"
                  >
                    <DraftsIcon
                      className={classes.customIcon}
                      color="primary"
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ textAlign: 'center' }}
                    >
                      Dealer Profiles
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                style={{
                  display: permissionss.ADMIN_MANUAL_INVITATION.WRITE
                    ? 'block'
                    : 'none',
                  margin: 10,
                  width: 300,
                }}
                onClick={(e) => {
                  routeToPage(e, 'sendInvitation');
                }}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    title="Contemplative Reptile"
                  >
                    <PersonAdd className={classes.customIcon} color="primary" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ textAlign: 'center' }}
                    >
                      Create Manual Invitation
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                style={{
                  display: permissionss.BULK_INVITATION.READ ? 'block' : 'none',
                  margin: 10,
                  width: 300,
                }}
                onClick={(e) => {
                  routeToPage(e, 'bulk-invitations');
                }}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    title="Contemplative Reptile"
                  >
                    <GroupAdd className={classes.customIcon} color="primary" />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ textAlign: 'center' }}
                    >
                      Bulk Invitation
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                style={{
                  display: permissionss.APP_CONFIG.WRITE ? 'block' : 'none',
                  margin: 10,
                  width: 300,
                }}
                onClick={(e) => {
                  routeToPage(e, 'app-config');
                }}
              >
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    title="Contemplative Reptile"
                  >
                    <SettingsIcon
                      className={classes.customIcon}
                      color="primary"
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      style={{ textAlign: 'center' }}
                    >
                      App Config
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
