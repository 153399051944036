/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { SupervisedUserCircle } from '@mui/icons-material';
import { Bar, Line } from 'react-chartjs-2';
import { Card, Grid, Paper, styled } from '@mui/material';
import reportApi from '../api/report.api';
import DashboadInvitationOverallStats from './DashboardInvitationOverallStats';
import CustomPie from './CustomPie';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function DashboardNewStats() {
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const [isLoading, setIsLoading] = useState(true);
  const [statsData, setStatsData] = useState({});
  const [userStatsData, setUserStatsData] = useState({});
  const [userCarStatsData, setUserCarStatsData] = useState({});
  const [appliedFilter, setAppliedFilter] = useState(null);
  const [invitationSource, setInvitationSource] = useState('Accepted');
  const [invitationYear, setInvitationYear] = useState(currentYear);
  const [invitationType, setInvitationType] = useState('WELCOME_KIT');
  // const inviteSources = Object.keys(statsData.invitationMonthlyStats || {});
  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const typeLabels = {
    WELCOME_KIT: 'Welcome Kit',
    FORM_SERVICE: 'Form Service',
    OPERATIONAL_ACQUISITION: 'IVR',
    ADMIN_PANEL_MANUAL_INVITE: 'Admin Manual',
    BULK_INVITE: 'Bulk Invite',
    GLOW_AGENT: 'Glow Agent',
    CLAIM_UPDATE: 'Claim Update',
    REIMBURSEMENT_INQUIRY: 'Reimbursement Inquiry',
    CUNA: 'CUNA',
    RENEWALS: 'Renewals',
    SUBSCRIPTIONS: 'Subscriptions',
    GEICO: 'Geico',
    RIVIAN: 'Rivian',
  };
  const colors = {
    CUNA: 'rgba(240, 128, 128, 1)',
    GEICO: 'rgba(233, 150, 122, 1)',
    RIVIAN: 'rgba(184, 134, 11, 1)',
    RENEWALS: 'rgba(47, 79, 79, 1)',
    SUBSCRIPTIONS: 'rgba(0, 206, 209, 1)',
    REIMBURSEMENT_INQUIRY: 'rgba(138, 43, 226, 1)',
    WELCOME_KIT: 'rgba(255, 99, 132, 1)',
    GLOW_AGENT: 'rgba(230, 246, 157, 1)',
    FORM_SERVICE: 'rgba(255, 228, 225, 1)',
    OPERATIONAL_ACQUISITION: 'rgba(255, 159, 64, 1)',
    ADMIN_PANEL_MANUAL_INVITE: 'rgba(153, 102, 255, 1)',
    BULK_INVITE: 'rgba(170, 222, 167, 1)',
    Accepted: 'rgba(46, 139, 87, 0.9)',
    Pending: 'rgba(220, 20, 60, 0.6)',
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart - Stacked',
      },
    },
  };

  const data = {
    labels,
    datasets: statsData.invitationMonthlyStats
      ? [
          ...statsData.invitationMonthlyStats[invitationSource].map(
            (source) => {
              return {
                label: typeLabels[source._id.inviteSource]
                  ? typeLabels[source._id.inviteSource]
                  : source._id.inviteSource,
                data: labels.map((_, i) => {
                  const obj = source.data.filter((o) => o.month - 1 === i);
                  return obj.length > 0 ? obj[0].count : null;
                }),
                backgroundColor: colors[source._id.inviteSource],
                stack: '0',
              };
            }
          ),
        ]
      : [],
  };

  const transformedData = { invitationMonthlyStats: {} };

  if (statsData.invitationMonthlyStats) {
    Object.keys(statsData.invitationMonthlyStats).forEach((inviteState) => {
      statsData.invitationMonthlyStats[inviteState].forEach((item) => {
        const { inviteSource } = item._id;
        if (!transformedData.invitationMonthlyStats[inviteSource]) {
          transformedData.invitationMonthlyStats[inviteSource] = [];
        }
        transformedData.invitationMonthlyStats[inviteSource].push({
          _id: {
            inviteSource: inviteState,
          },
          data: item.data,
        });
      });
    });
  }

  const inviteData = {
    labels,
    datasets: transformedData.invitationMonthlyStats
      ? [
          ...(transformedData.invitationMonthlyStats[invitationType] || []).map(
            (source) => {
              return {
                label: typeLabels[source._id.inviteSource]
                  ? typeLabels[source._id.inviteSource]
                  : source._id.inviteSource,
                data: labels.map((_, i) => {
                  const obj = source.data.filter((o) => o.month - 1 === i);
                  return obj.length > 0 ? obj[0].count : null;
                }),
                backgroundColor: colors[source._id.inviteSource],
                stack: '0',
              };
            }
          ),
        ]
      : [],
  };

  const fetchDashboardData = async () => {
    const params = {
      filter: JSON.stringify(appliedFilter),
      year: invitationYear,
    };
    setIsLoading(true);
    return reportApi.getDashboardStats(params, dispatch);
  };

  const fetchUserStats = async () => {
    const params = {
      filter: JSON.stringify(appliedFilter),
    };
    setIsLoading(true);
    return reportApi.getDashboardUserStats(params, dispatch);
  };

  const fetchDashboardUserCarStats = async () => {
    const params = {
      filter: JSON.stringify(appliedFilter),
    };
    setIsLoading(true);
    return reportApi.getDashboardUserCarStats(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchDashboardData().then(setStatsData),
        fetchUserStats().then(setUserStatsData),
        fetchDashboardUserCarStats().then(setUserCarStatsData),
      ]);
      setIsLoading(false);
    };
    loadData();
  }, [appliedFilter]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await Promise.all([
        fetchDashboardData().then(setStatsData),
        fetchUserStats().then(setUserStatsData),
        fetchDashboardUserCarStats().then(setUserCarStatsData),
      ]);
      setIsLoading(false);
    };
    loadData();
  }, [invitationYear]);

  const handleInvitationSourceChange = (params) => {
    setInvitationType(params.target.value);
  };

  const handleInvitationTypeChange = (params) => {
    setInvitationSource(params.target.value);
  };
  const handleInvitationYearChange = (params) => {
    setInvitationYear(params.target.value);
  };

  const region = process.env.REACT_APP_DEFAULT_REGION;
  const gridColumns = region === 'UK' ? 4 : 3;

  return (
    <div
      className="container-fluid"
      style={{ paddingLeft: 100, paddingRight: 100 }}
    >
      <div className="row">
        <div className="col-sm-12">
          <Card className="p-2">
            <h4 style={{ textAlign: 'left' }}>User Stats</h4>
            <Grid container spacing={0.5}>
              <Grid item xs={gridColumns}>
                <Item
                  className="d-flex justify-content-left align-items-center"
                  style={{ paddingLeft: 20, minHeight: 100, marginBottom: 10 }}
                >
                  <SupervisedUserCircle color="disabled" fontSize="large" />
                  <p className="card-content-align">
                    <b>{userStatsData ? userStatsData.AllUsers : 0}</b>
                    <br />
                    <span>Total Users</span>
                  </p>
                </Item>
              </Grid>
              <Grid item xs={gridColumns}>
                <Item
                  className="d-flex justify-content-left align-items-center"
                  style={{ paddingLeft: 20, minHeight: 100, marginBottom: 10 }}
                >
                  <SupervisedUserCircle color="disabled" fontSize="large" />
                  <p className="card-content-align">
                    <b>{userStatsData ? userStatsData.MobileUsers : 0}</b>
                    <br />
                    <span>Mobile Users</span>
                  </p>
                </Item>
              </Grid>
              <Grid item xs={gridColumns}>
                <Item
                  className="d-flex justify-content-left align-items-center"
                  style={{ paddingLeft: 20, minHeight: 100, marginBottom: 10 }}
                >
                  <SupervisedUserCircle color="disabled" fontSize="large" />
                  <p className="card-content-align">
                    <b>{userStatsData ? userStatsData.VerifiedUsers : 0}</b>
                    <br />
                    <span>Verified Users</span>
                  </p>
                </Item>
              </Grid>
              {region !== 'UK' && (
                <Grid item xs={gridColumns}>
                  <Item
                    className="d-flex justify-content-left align-items-center"
                    style={{
                      paddingLeft: 20,
                      minHeight: 100,
                      marginBottom: 10,
                    }}
                  >
                    <SupervisedUserCircle color="disabled" fontSize="large" />
                    <p className="card-content-align">
                      <b>
                        {userCarStatsData
                          ? userCarStatsData.UsersWithAtleastACar
                          : 0}
                      </b>
                      <br />
                      <span>Total Users with atleast 1 Car</span>
                    </p>
                  </Item>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={0.5}>
              {region !== 'UK' && (
                <>
                  <Grid item xs={gridColumns}>
                    <Item
                      className="d-flex justify-content-left align-items-center"
                      style={{
                        paddingLeft: 20,
                        minHeight: 100,
                        marginBottom: 10,
                      }}
                    >
                      <SupervisedUserCircle color="disabled" fontSize="large" />
                      <p className="card-content-align">
                        <b>{userStatsData ? userStatsData.VCP1Users : 0}</b>
                        <br />
                        <span>VCP 1.0 Users</span>
                      </p>
                    </Item>
                  </Grid>
                  <Grid item xs={gridColumns}>
                    <Item
                      className="d-flex justify-content-left align-items-center"
                      style={{
                        paddingLeft: 20,
                        minHeight: 100,
                        marginBottom: 10,
                      }}
                    >
                      <SupervisedUserCircle color="disabled" fontSize="large" />
                      <p className="card-content-align">
                        <b>
                          {userStatsData ? userStatsData.VCP1MigratedUsers : 0}
                        </b>
                        <br />
                        <span>VCP 1.0 Migrated Users</span>
                      </p>
                    </Item>
                  </Grid>
                  <Grid item xs={gridColumns}>
                    <Item
                      className="d-flex justify-content-left align-items-center"
                      style={{
                        paddingLeft: 20,
                        minHeight: 100,
                        marginBottom: 10,
                      }}
                    >
                      <SupervisedUserCircle color="disabled" fontSize="large" />
                      <p className="card-content-align">
                        <b>
                          {userStatsData ? userStatsData.VCP1PendingUsers : 0}
                        </b>
                        <br />
                        <span>VCP 1.0 Pending Users</span>
                      </p>
                    </Item>
                  </Grid>
                </>
              )}
              {region === 'UK' && (
                <Grid item xs={gridColumns}>
                  <Item
                    className="d-flex justify-content-left align-items-center"
                    style={{
                      paddingLeft: 20,
                      minHeight: 100,
                      marginBottom: 10,
                    }}
                  >
                    <SupervisedUserCircle color="disabled" fontSize="large" />
                    <p className="card-content-align">
                      <b>
                        {userCarStatsData
                          ? userCarStatsData.UsersWithAtleastACar
                          : 0}
                      </b>
                      <br />
                      <span>Total Users with atleast 1 Car</span>
                    </p>
                  </Item>
                </Grid>
              )}
              <Grid item xs={gridColumns}>
                <Item
                  className="d-flex justify-content-left align-items-center"
                  style={{ paddingLeft: 20, minHeight: 100, marginBottom: 10 }}
                >
                  <SupervisedUserCircle color="disabled" fontSize="large" />
                  <p className="card-content-align">
                    <b>
                      {userCarStatsData
                        ? userCarStatsData.VerifiedUsersWithAtleastACar
                        : 0}
                    </b>
                    <br />
                    <span>Total Verified Users with atleast 1 Car</span>
                  </p>
                </Item>
              </Grid>
            </Grid>
          </Card>
          <br />
          <Card>
            <Row>
              <Col>
                <DashboadInvitationOverallStats />
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <Row>
              <Col>
                <div className="container-fluid">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-sm-4">
                      <h4 style={{ textAlign: 'left' }}>
                        Invitation Monthly Stats
                      </h4>
                    </div>
                    <div className="col-3" style={{ marginTop: '10px' }}>
                      <select
                        style={{
                          width: '100%',
                          padding: 10,
                          borderColor: '#ccc',
                        }}
                        value={invitationSource}
                        onChange={handleInvitationTypeChange}
                      >
                        <option value="Accepted">Accepted</option>
                        <option value="Pending">Pending</option>
                      </select>
                    </div>
                    <div className="col-3" style={{ marginTop: '10px' }}>
                      <select
                        style={{
                          width: '100%',
                          padding: 10,
                          borderColor: '#ccc',
                        }}
                        value={invitationYear}
                        onChange={handleInvitationYearChange}
                      >
                        {years.map((year) => (
                          <option value={year}>{year}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Bar height={100} data={data} />
                </div>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <Row>
              <Col>
                <div className="container-fluid">
                  <div className="row justify-content-between align-items-center">
                    <div className="col-sm-4">
                      <h4 style={{ textAlign: 'left' }}>
                        Invitation Type Monthly Stats
                      </h4>
                    </div>
                    <div className="col-3" style={{ marginTop: '10px' }}>
                      <select
                        style={{
                          width: '100%',
                          padding: 10,
                          borderColor: '#ccc',
                        }}
                        value={invitationType}
                        onChange={handleInvitationSourceChange}
                        defaultValue="WELCOME_KIT"
                      >
                        {/* <option value="">All</option> */}
                        <option value="WELCOME_KIT">Welcome Kit</option>
                        <option value="OPERATIONAL_ACQUISITION">IVR</option>
                        <option value="GLOW_AGENT">Glow Agent</option>
                        <option value="CUNA">CUNA</option>
                        <option value="REIMBURSEMENT_INQUIRY">
                          Reimbursement Inquiry
                        </option>
                        <option value="RENEWALS">Renewals</option>
                        <option value="SUBSCRIPTIONS">Subscriptions</option>
                        <option value="GEICO">Geico</option>
                        <option value="RIVIAN">Rivian</option>
                        <option value="BULK_INVITE">Bulk Invite</option>
                        <option value="ADMIN_PANEL_MANUAL_INVITE">
                          Admin Panel Manual Invite
                        </option>
                        <option value="FORM_SERVICE">Form Service</option>
                      </select>
                    </div>
                    <div className="col-3" style={{ marginTop: '10px' }}>
                      <select
                        style={{
                          width: '100%',
                          padding: 10,
                          borderColor: '#ccc',
                        }}
                        value={invitationYear}
                        onChange={handleInvitationYearChange}
                      >
                        {years.map((year) => (
                          <option value={year}>{year}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Bar height={100} data={inviteData} options={{}} />
                </div>
              </Col>
            </Row>
          </Card>
          <br />
          <Card>
            <Row>
              <Col>
                <CustomPie
                  title="Users Source"
                  stats={statsData.userSourceStats}
                />
              </Col>
              <Col>
                <CustomPie
                  title="Users Origin Stats"
                  stats={statsData.userOriginStats}
                />
              </Col>
              <Col>
                <CustomPie
                  title="Users Source Category"
                  stats={statsData.userSourceCatStats}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DashboardNewStats;
