import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './updateGoogleDealer.style.scss';
import { Button, Card, Input, InputGroup, InputGroupText } from 'reactstrap';
import _ from 'lodash';
import Carousel from 'react-multi-carousel';
import { Close, Search } from '@mui/icons-material';
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
import dcrApi from '../../api/dcr.api';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function UpdateGoogleDealerDialog(props) {
  const { item, setOpenUpdateGoogleDealer, postUpdateAction } = props;
  const zoom = useRef(10);
  const carouselRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState({ lat: 43.5769843, lng: -79.7745306 });
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [query, setQuery] = useState(
    `${item.name}, ${item.billingStreet}, ${item.billingCity} ${item.billingPostalCode}`
  );

  const debounceFilter = _.debounce(async (value) => {
    setQuery(value);
  }, 1000);
  const handleInputChange = (event) => {
    const { value } = event.target;
    debounceFilter(value);
  };

  // const fetchCandidates = async () => {
  // dcrApi.fetchGoogleCandidates(params);
  // };

  const searchCandidate = async () => {
    setLoading(true);
    try {
      const data = await dcrApi.searchGoogleCandidates({
        query,
        type: 'car_dealer',
      });
      setLoading(false);
      if (data && data.results) {
        setCandidates(data.results);
        setCenter({
          lat: data.results[0].geometry.location.lat,
          lng: data.results[0].geometry.location.lng,
        });
        setSelectedIndex(null);
      }
    } catch (er) {
      setLoading(false);
    }
  };

  const updateGoogleCandidate = async (placeId) => {
    const params = {
      accountNumber: item.accountNumber,
      placeId,
    };
    setLoading(true);
    await dcrApi.updateGoogleCandidate(params);
    setLoading(false);
    postUpdateAction();
  };

  useEffect(() => {
    searchCandidate();
  }, [query]);

  const handleSelected = (candidate, index) => {
    setSelectedIndex(index);
    zoom.current = 15;
    setCenter({
      lat: candidate.geometry.location.lat,
      lng: candidate.geometry.location.lng,
    });
  };

  return (
    <Dialog fullScreen open>
      <DialogContent>
        <div className="ugd-container">
          <div
            className="edit-dcr-close-btn"
            onClick={() => setOpenUpdateGoogleDealer(false)}
          >
            <Close />
          </div>
          <div className="ugd-search-container">
            <InputGroup className="ugd-search">
              <div className="input-group-append">
                <span className="input-group-text" id="basic-addon2">
                  <InputGroupText>
                    <Search />
                  </InputGroupText>
                </span>
              </div>
              <Input
                onChange={handleInputChange}
                name="name"
                placeholder="Search"
                defaultValue={query}
              />
            </InputGroup>
          </div>
          <div className="ugd-map-conatiner">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            >
              <GoogleMap
                mapContainerClassName="ugd-map-conatiner"
                zoom={zoom.current}
                center={center}
              >
                {candidates.map((m, i) => (
                  <Marker
                    key={m.place_id}
                    position={{
                      lat: m.geometry.location.lat,
                      lng: m.geometry.location.lng,
                    }}
                    animation={
                      selectedIndex === i
                        ? window.google.maps.Animation.BOUNCE
                        : null
                    }
                    onClick={() => {
                      handleSelected(m, i);
                      carouselRef.current.goToSlide(i);
                    }}
                  />
                ))}
              </GoogleMap>
            </LoadScript>
          </div>
          <div className="ugd-dealer-list-container">
            {loading ? (
              <div className="w-100 progress-indicator-container">
                <CircularProgress />
              </div>
            ) : (
              <Carousel
                ref={carouselRef}
                autoPlay={false}
                infinite={false}
                shouldResetAutoplay={false}
                arrows
                responsive={responsive}
              >
                {candidates.map((itm, index) => {
                  return (
                    <DealerCard
                      index={index}
                      updateGoogleCandidate={updateGoogleCandidate}
                      selected={index === selectedIndex}
                      item={itm}
                      handleSelected={handleSelected}
                    />
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function DealerCard(props) {
  const { item, selected, index, handleSelected, updateGoogleCandidate } =
    props;
  const handleClick = () => {
    handleSelected(item, index);
  };
  return (
    <Card
      onClick={handleClick}
      className={`ugd-card ${selected ? 'selected' : ''}`}
    >
      <div className="w-100 gray-bg">
        {item.photos && item.photos.length > 0 && (
          <img className="ugd-card-image" alt="Dealer" src={item.photos[0]} />
        )}
      </div>
      <div className="ugd-card-details-container">
        <div className="ugd-card-dealer-name">{item.name}</div>
        <div className="ugd-card-dealer-detail">{item.phone}</div>
        <div className="ugd-card-dealer-detail">{item.website}</div>
        <div className="ugd-card-dealer-detail">{item.formatted_address}</div>
      </div>
      <div className="ugd-card-details-footer">
        {/* <div className="ugd-card-dealer-detail">Monday 8AM-7:30PM</div> */}
        <Button
          onClick={() => updateGoogleCandidate(item.place_id)}
          color="primary"
          className="ugd-select-btn"
        >
          Select
        </Button>
      </div>
    </Card>
  );
}

export default UpdateGoogleDealerDialog;
