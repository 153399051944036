import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Close,
  DownloadForOfflineOutlined,
  RemoveRedEyeOutlined,
} from '@mui/icons-material';
import reportApi from '../api/report.api';

function HTMLViewer({ title, htmlPath }) {
  const dispatch = useDispatch();
  const [body, setBody] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(false);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const fetchS3AssetContent = async (path) => {
    setOverlayLoading(true);
    try {
      const params = {
        objectKey: path,
      };
      const response = await reportApi.getS3ObjectStringContent(
        params,
        dispatch
      );
      const html = response.stringContent;
      setOverlayLoading(false);
      return html;
    } catch (error) {
      return '';
    } finally {
      setOverlayLoading(false);
    }
  };

  const downloadS3AssetFile = async () => {
    setOverlayLoading(true);
    try {
      const params = {
        path: htmlPath,
      };
      const response = await reportApi.getS3AssetSignedUrl(params, dispatch);
      window.open(response.signedUrl, '_blank');
    } catch (error) {
      return;
    } finally {
      setOverlayLoading(false);
    }
  };

  const showAssetContent = async () => {
    setOpenModal(true);
    setOverlayLoading(true);
    try {
      const html = await fetchS3AssetContent(htmlPath);
      setBody(html);
    } catch (error) {
      return;
    } finally {
      setOverlayLoading(false);
    }
  };

  return (
    <div>
      <IconButton onClick={() => showAssetContent()}>
        <RemoveRedEyeOutlined color="primary" />
      </IconButton>
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        fullWidth
        maxWidth="lg"
        style={{
          overflow: 'hidden',
        }}
      >
        <DialogTitle disableTypography className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            {title}
          </Typography>
          <IconButton onClick={downloadS3AssetFile}>
            <DownloadForOfflineOutlined color="primary" />
          </IconButton>
          <IconButton onClick={handleModalClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            minHeight: '500px',
            overflow: 'auto',
          }}
        >
          {overlayLoading && (
            <CircularProgress
              style={{ height: '50px', width: '50px', padding: '50 px' }}
            />
          )}
          {body && !overlayLoading && (
            <div style={{ overflow: 'hidden' }}>
              <iframe
                title="Body"
                srcDoc={body}
                style={{
                  border: 'none',
                  width: '100%',
                  height: '500px',
                  overflow: 'hidden',
                }}
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HTMLViewer;
