import moment from 'moment';

export function formatDateTime(dateTime) {
  return moment(dateTime).format(process.env.REACT_APP_DATE_TIME_FORMAT);
}

export function formatDate(dateTime) {
  return moment(dateTime).format(process.env.REACT_APP_DATE_FORMAT);
}

export function formatDateWithFormat(dateTime, format) {
  return moment(dateTime).format(format);
}
