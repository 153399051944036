import axios from 'axios';
import { API_ERROR, CLEAR_API_ERROR } from '../store/types';
import { DEFAULT_TIMEOUT } from '../constants';

let _apiToken;
let selectedCountry;

const setApiToken = (token) => {
  if (token) {
    _apiToken = token.accessToken;
  }
};
const setCountry = (country) => {
  selectedCountry = country;
};

const DEFAULT_URL = process.env.REACT_APP_API_URL;
const DEFAULT_SUBSCRIPTIONS_URL = process.env.REACT_APP_SUBSCRIPTIONS_API_URL;
let _url;
let _urlSubscriptions;

const setUrl = (url) => {
  _url = url;
};

const getUrl = () => {
  return _url || DEFAULT_URL;
};

const setSubscriptionsUrl = (url) => {
  _urlSubscriptions = url;
};

const getSubscriptionsUrl = () => {
  return _urlSubscriptions || DEFAULT_SUBSCRIPTIONS_URL;
};

const pocketDriveApi = async (
  dispatch,
  method,
  url,
  data,
  params,
  additionalHeaders
) => {
  try {
    const headers = {
      ...additionalHeaders,
      authorization: `Bearer ${_apiToken}`,
      'x-vcp-country': selectedCountry,
    };
    const response = await axios({
      method,
      url: getUrl() + url,
      data,
      params,
      headers,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: API_ERROR,
      payload: err.message || err,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_API_ERROR,
      });
    }, DEFAULT_TIMEOUT);
    return null;
  }
};

const _pocketDriveApi = async (
  dispatch,
  method,
  url,
  data,
  params,
  additionalHeaders
) => {
  try {
    const headers = {
      ...additionalHeaders,
      authorization: `Bearer ${_apiToken}`,
      'x-vcp-country': selectedCountry,
    };
    const response = await axios({
      method,
      url: getUrl() + url,
      data,
      params,
      headers,
    });
    return response;
  } catch (err) {
    dispatch({
      type: API_ERROR,
      payload: err.message || err,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_API_ERROR,
      });
    }, DEFAULT_TIMEOUT);
    return err;
  }
};

const pocketDrivePublicApi = async (
  dispatch,
  method,
  url,
  data,
  params,
  additionalHeaders
) => {
  try {
    const headers = {
      ...additionalHeaders,
    };
    const response = await axios({
      method,
      url: getUrl() + url,
      data,
      params,
      headers,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: API_ERROR,
      payload: err.message || err,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_API_ERROR,
      });
    }, DEFAULT_TIMEOUT);
    return null;
  }
};

const suppressApiErrors = () => {};

const pocketDriveSubscriptionsApi = async (
  dispatch,
  method,
  url,
  data,
  params,
  additionalHeaders
) => {
  try {
    const headers = {
      ...additionalHeaders,
      authorization: `Bearer ${_apiToken}`,
    };
    const response = await axios({
      method,
      url: getSubscriptionsUrl() + url,
      data,
      params,
      headers,
    });
    return response.data;
  } catch (err) {
    dispatch({
      type: API_ERROR,
      payload: err.message || err,
    });
    setTimeout(() => {
      dispatch({
        type: CLEAR_API_ERROR,
      });
    }, DEFAULT_TIMEOUT);
    return null;
  }
};

export default {
  setApiToken,
  pocketDriveApi,
  pocketDriveSubscriptionsApi,
  suppressApiErrors,
  pocketDrivePublicApi,
  _pocketDriveApi,
  setUrl,
  getUrl,
  setSubscriptionsUrl,
  getSubscriptionsUrl,
  setCountry,
};
