import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import FaqsMappingList from '../components/FaqsMappingList';
import faqsApi from '../api/faqs.api';
import { PRODUCT_TYPES } from '../constants';

function CreateFaqMappingPopup({ open, onClose, onSave }) {
  const [faq, setFaq] = useState({
    mappingType: 'CLIENT',
    mappingId: '',
    tagIds: [],
    faqsList: [],
    appRegion: process.env.REACT_APP_DEFAULT_REGION,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaq({ ...faq, [name]: value });
  };

  const handleSave = () => {
    onSave(faq); // Call the function passed from parent to save the FAQ data
    onClose(); // Close the popup after saving
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New FAQ Mapping</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Mapping Type</InputLabel>
          <Select
            name="mappingType"
            value={faq.mappingType}
            onChange={handleInputChange}
          >
            <MenuItem value="CLIENT">CLIENT</MenuItem>
            <MenuItem value="PRODUCT_TYPE">PRODUCT TYPE</MenuItem>
          </Select>
        </FormControl>
        {faq.mappingType === 'CLIENT' ? (
          <TextField
            name="mappingId"
            label="Mapping ID"
            fullWidth
            value={faq.mappingId}
            onChange={handleInputChange}
            margin="normal"
          />
        ) : faq.mappingType === 'PRODUCT_TYPE' ? (
          <FormControl fullWidth margin="normal">
            <InputLabel>Mapping ID</InputLabel>
            <Select
              name="mappingId"
              value={faq.mappingId}
              onChange={handleInputChange}
              MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}
            >
              {Object.entries(PRODUCT_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FaqsMapping() {
  const [openPopup, setOpenPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  const handleCreateFaqMapping = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSaveFaqMapping = async (faqData) => {
    try {
      const result = await faqsApi.createFaqsMapping(dispatch, faqData);
      setSnackbarMessage('FAQ Mapping added successfully');
      setSnackbarOpen(true);
      setOpenPopup(false);
      setTimeout(() => {
        window.open(`/faqs-mapping/${result._id}`, '_blank');
      }, 2000);
    } catch (error) {
      setSnackbarMessage('Failed to add FAQ Mapping');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />

          <>
            <Button
              color="primary"
              onClick={handleCreateFaqMapping}
              variant="contained"
              sx={{
                padding: '6px 16px',
                fontSize: '0.875rem',
                textTransform: 'none',
              }}
            >
              Create FAQ Mapping
            </Button>

            <CreateFaqMappingPopup
              open={openPopup}
              onClose={handleClosePopup}
              onSave={handleSaveFaqMapping}
              currentUser
            />
          </>
        </h5>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="row my-2 ">
        <FaqsMappingList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default FaqsMapping;
