import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Button,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { addMonths, addDays, format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import JSONViewer from './JSONViewer';
import subscriptionsApi from '../api/subscriptions.api';
import { PAGINATION_OPTIONS } from '../constants';
import FilterModal from './Filter';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Recurring File Logs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function RecurringFileLogList({ viewHeight = '30vh' }) {
  const columns = [
    {
      headerName: 'id',
      field: 'id',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Recurring File Logs" jsonData={params.row} />
            {params.row.id}
          </div>
        );
      },
      width: 350,
    },
    {
      headerName: 'sfdcId',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer
              title="Raw SFDC Record JSON"
              jsonData={JSON.parse(params.row.rawSfdcRecord)}
            />
            {params.row.sfdcId}
          </div>
        );
      },
      field: 'sfdcId',
      headerClassName: 'custom-dark-theme--header',
      width: 240,
    },
    {
      headerName: 'contractNumber',
      render: (e) => e.contractNumber,
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'vinNumber',
      field: 'vinNumber',
      renderCell: (c) => JSON.parse(c.row.rawSfdcRecord).vinNumber,
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'rootTransactionNumber',
      field: 'rootTransactionNumber',
      renderCell: (c) =>
        c.row.rootTransactionNumber
          ? c.row.rootTransactionNumber
          : JSON.parse(c.row.rawSfdcRecord).referenceTransactionUUID,
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'processStatus',
      render: (e) => e.processStatus,
      field: 'processStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'accountPaymentId',
      field: 'accountPaymentId',
      renderCell: (c) =>
        c.row.accountPaymentId
          ? c.row.accountPaymentId
          : JSON.parse(c.row.rawSfdcRecord).accountPayment,
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'accountPaymentEmail',
      field: 'accountPaymentEmail',
      renderCell: (c) => JSON.parse(c.row.rawSfdcRecord).accountPaymentEmail,
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'amount',
      field: 'amount',
      renderCell: (c) =>
        `${JSON.parse(c.row.rawSfdcRecord).currency} ${
          JSON.parse(c.row.rawSfdcRecord).amount
        }`,
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'paymentMode',
      render: (e) => e.paymentMode,
      field: 'paymentMode',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'nextPaymentDueDate',
      render: (e) => e.nextPaymentDueDate,
      field: 'nextPaymentDueDate',
      headerClassName: 'custom-dark-theme--header',
      width: 170,
    },
    {
      headerName: 'cancellationDate',
      render: (e) => e.cancellationDate,
      field: 'cancellationDate',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'merchantTransactionID',
      field: 'merchantTransactionID',
      renderCell: (c) => JSON.parse(c.row.rawSfdcRecord).merchantTransactionID,
      headerClassName: 'custom-dark-theme--header',
      width: 360,
    },
    {
      headerName: 'createdAt',
      render: (e) => e.createdAt,
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 210,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contractNumber, setContractNumber] = useState('');
  const [sfdcId, setSfdcId] = useState('');

  const [filter, setFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    let dateDisplay = '';
    if (filterAfterDate || filterBeforeDate) {
      const startDate = filterAfterDate
        ? format(new Date(filterAfterDate), 'yyyy-MM-dd')
        : '1970-01-01';
      const endDate = filterBeforeDate
        ? format(new Date(filterBeforeDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd');
      newFilter.created_at = { between: [startDate, endDate] };
      dateDisplay = `Dates: ${startDate} - ${endDate}`;
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && filterAfterDate && filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate, filterAfterDate]);

  const setLastDayFilter = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(new Date());
    // setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const filterValue = { ...filter };
    if (sfdcId) {
      filterValue.sfdc_id = { eq: sfdcId };
    }
    if (contractNumber) {
      filterValue.contract_number = { eq: contractNumber };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    setIsLoading(true);

    return subscriptionsApi.getRecurringFileLogs(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.data.totalItems;
        const currentPageRecords = reports.data.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter, contractNumber, sfdcId]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const resetFilters = () => {
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setFilter({});
    setDataSource([]);
    setPage(0);
    setContractNumber('');
    setSfdcId('');
  };

  const handleFilterOfferId = (event) => {
    setDataSource([]);
    setPage(0);
    setSfdcId(event.target.value);
  };

  const handleFilterContractNumber = (event) => {
    setDataSource([]);
    setPage(0);
    setContractNumber(event.target.value);
  };

  return (
    <div className="container">
      <div style={{ padding: 5 }} className="d-flex">
        <div className="flex-grow-1" />
        <div className="d-flex align-items-center">
          <FormControl style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}>
            <InputLabel id="input-sfdc-id" shrink>
              SFDC Id
            </InputLabel>
            <Input
              labelId="input-sfdc-id"
              id="input-sfdc"
              value={sfdcId}
              fullWidth
              onChange={handleFilterOfferId}
            />
          </FormControl>
          <FormControl style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}>
            <InputLabel id="input-contract-number" shrink>
              Contract Number
            </InputLabel>
            <Input
              labelId="input-contract-number"
              id="input-contract"
              value={contractNumber}
              fullWidth
              onChange={handleFilterContractNumber}
            />
          </FormControl>
          {(contractNumber || sfdcId) && (
            <IconButton onClick={resetFilters}>
              <Cancel color="error" />
            </IconButton>
          )}
        </div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />
          {filter && !Object.keys(filter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}
          <FilterModal
            currentFilters={filter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h5>
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RecurringFileLogList;
