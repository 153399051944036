import api from '.';

const REPORT_URL = `/admin/faqs`;

const getFaqs = async (dispatch, params) => {
  return api.pocketDriveApi(dispatch, 'get', `${REPORT_URL}`, null, params);
};

const createFaqs = async (dispatch, data) => {
  return api.pocketDriveApi(dispatch, 'post', `${REPORT_URL}`, data);
};

const deleteFaqs = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'delete', `${REPORT_URL}/${id}`);
};

const updateFaqs = async (dispatch, id, data) => {
  return api.pocketDriveApi(dispatch, 'patch', `${REPORT_URL}/${id}`, data);
};

const getFaqsTagsList = async (dispatch, params) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/tags-list`,
    null,
    params
  );
};

const createFaqsTags = async (dispatch, data) => {
  return api.pocketDriveApi(dispatch, 'post', `${REPORT_URL}/faqTag`, data);
};

const deleteFaqsTags = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'delete', `${REPORT_URL}/faqTag/${id}`);
};

const updateFaqsTags = async (dispatch, id, data) => {
  return api.pocketDriveApi(
    dispatch,
    'patch',
    `${REPORT_URL}/faqTag/${id}`,
    data
  );
};

const getFaqsMappingList = async (dispatch, params) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/faqMappings-list`,
    null,
    params
  );
};

const getFaqsMappingByDetailsById = async (dispatch, id) => {
  return api.pocketDriveApi(dispatch, 'get', `${REPORT_URL}/faqMapping/${id}`);
};

const createFaqsMapping = async (dispatch, data) => {
  return api.pocketDriveApi(dispatch, 'post', `${REPORT_URL}/faqMapping`, data);
};

const updateFaqsMapping = async (dispatch, id, data) => {
  return api.pocketDriveApi(
    dispatch,
    'patch',
    `${REPORT_URL}/faqMapping/${id}`,
    data
  );
};

export default {
  getFaqs,
  createFaqs,
  updateFaqs,
  deleteFaqs,
  getFaqsTagsList,
  createFaqsTags,
  deleteFaqsTags,
  updateFaqsTags,
  getFaqsMappingList,
  getFaqsMappingByDetailsById,
  createFaqsMapping,
  updateFaqsMapping,
};
