export const API_ERROR = 'API_ERROR';
export const CLEAR_API_ERROR = 'CLEAR_API_ERROR';

export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const GET_USER_REPORTS = 'GET_USER_REPORTS';
export const GET_USER_ME = 'GET_USER_ME';
export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const DELETE_USER_PERMISSIONS = 'DELETE_USER_PERMISSIONS';

export const CREATE_CAR = 'CREATE_CAR';
export const GET_CARS = 'GET_CARS';
export const GET_CAR = 'GET_CAR';
export const GET_CAR_CONTRACTS = 'GET_CAR_CONTRACTS';
export const UPDATE_CAR = 'UPDATE_CAR';
export const UPDATE_CAR_GLOVE_BOX = 'UPDATE_CAR_GLOVE_BOX';
export const DELETE_CAR = 'DELETE_CAR';
export const ADD_CREDIT_TRANSACTION = 'ADD_CREDIT_TRANSACTION';
export const MARK_CREDIT_USED = 'MARK_CREDIT_USED';

export const GET_ERRORS = 'GET_ERRORS';
export const GET_DETAILED_ERRORS = 'GET_DETAILED_ERRORS';

export const GET_CAR_BACKGROUND_IMAGES = 'GET_CAR_BACKGROUND_IMAGES';
export const CREATE_CAR_BACKGROUND_IMAGE = 'CREATE_CAR_BACKGROUND_IMAGE';
export const UPDATE_CAR_BACKGROUND_IMAGE = 'UPDATE_CAR_BACKGROUND_IMAGE';
export const DELETE_CAR_BACKGROUND_IMAGE = 'DELETE_CAR_BACKGROUND_IMAGE';

export const GET_SMSES = 'GET_SMSES';

export const GET_LOCALIZED_STRINGS = 'GET_LOCALIZED_STRINGS';
export const UPDATE_LOCALIZED_STRING = 'UPDATE_LOCALIZED_STRING';

export const UPDATE_APP_API_URL = 'UPDATE_APP_API_URL';

export const CONTACT_US_FAILED_REQ = 'CONTACT_US_FAILED_REQ';
export const UPDATE_SELECTED_COUNTRY = 'UPDATE_SELECTED_COUNTRY';
