/* eslint-disable default-param-last */
import React, { useEffect, useState, useRef } from 'react';
import { Grid, LinearProgress, TextField, Autocomplete } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';
import { Close as CloseIcon } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
  '& .custom-link-cell': {
    color: 'blue !important',
    cursor: 'pointer',
  },
});

const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

function SubscriptionsDealerReport(props) {
  const dispatch = useDispatch();
  const { filter } = props;
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedDealers, setSelectedDealers] = useState(null);
  const [resetStatus, setResetStatus] = useState(false);
  const dataGridRef = useRef();

  const columns = [
    {
      headerName: 'Total',
      field: 'totalDealerRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 175,
    },
    {
      headerName: 'Active',
      field: 'totalActiveRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Expired',
      field: 'totalExpiredRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Redeemed',
      field: 'totalRedeemedRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(filter || {});
  const previousFilter = usePrevious(appliedFilter);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [dealerId, setDealerId] = useState('');
  const [dealerIds, setDealerIds] = useState([]);

  function transformDataToGridFormat(data, startIndex) {
    return { ...data, _gridId: startIndex };
  }

  function updateDataSource(data) {
    const startIndex = 0; // Always start from 0 for the new data
    // Transform the incoming data to the required format
    const transformedData = [transformDataToGridFormat(data, startIndex)];
    setDataSource(transformedData);
    return transformedData;
  }

  async function fetchData(id) {
    setIsLoading(true);
    try {
      const reports = await subscriptionsApi.getOfferStatsByDealer(
        dispatch,
        id
      );
      if (reports && reports.data) {
        setRowCount(reports.totalCount || 1);
        updateDataSource(reports.data);
      }
    } catch (error) {
      /* Empty */
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async (appliedFilterUpdated) => {
      if (!checkIfDataPresent() || appliedFilterUpdated) {
        if (appliedFilterUpdated && page !== 0) {
          setDataSource([]);
          return;
        }
        setIsLoading(true);
        const reports = await fetchData(
          dealerId // Pass dealerId here
        );
        if (reports && reports.data) {
          updateDataSource(reports.data);
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
        }
        setIsLoading(false);
      }
    };
    const appliedFilterUpdated =
      JSON.stringify(appliedFilter) !== JSON.stringify(previousFilter);
    loadData(appliedFilterUpdated);
  }, []); // Add dealerId to the dependency array

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  const saveFilters = () => {
    const newFilter = {};
    if (dealerId && dealerId.length > 0) {
      newFilter.sellingDealerId = { $eq: dealerId };
    }
    setAppliedFilter(newFilter);
  };

  useEffect(() => {
    if (applyQuickFilter) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter]);

  useEffect(() => {
    if (resetStatus) {
      setResetStatus(false);
      saveFilters();
    }
  }, [resetStatus]);

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  async function fetchAndSetDealerIds(skip = 0, limit = 500, pipeLine = {}) {
    const params = {
      skip,
      limit,
      filter: JSON.stringify(pipeLine),
    };
    const response = await subscriptionsApi.getOfferDealers(dispatch, params);
    if (response && response.data) {
      const dealers = response.data.map((dealer) => ({
        dealerId: dealer.dealerId,
        name: dealer.dealerName,
      }));
      setDealerIds(dealers);
    }
  }

  useEffect(() => {
    const fetchDealerData = async () => {
      await fetchAndSetDealerIds();
    };
    fetchDealerData();
  }, []);

  const handleDealerIdsChange = async (event, option) => {
    setDataSource([]);
    if (option) {
      setSelectedDealers(option);
      const selectedDealerId = option.dealerId;
      setDealerId(selectedDealerId);
      // Fetch data with the new dealerId and update the data source
      const reports = await fetchData(selectedDealerId);
      if (reports && reports.data) {
        setRowCount(reports.totalCount || 0);
      }
    } else {
      setDataSource([]);
      setSelectedDealers(null);
      setDealerId(''); // Clear the dealerId
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    }
  };

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        <div className="d-flex flex-column align-items-end mb-2">
          <div className="d-flex justify-content-between align-items-center w-100 mb-2 flex-wrap">
            <div className="d-flex align-items-center">
              <h2 className="mr-2" style={{ marginRight: '20px' }}>
                Subscription Dealer Report
              </h2>
            </div>
            <div className="d-flex align-items-center">
              <Autocomplete
                id="select-dealerId"
                options={dealerIds}
                autoHighlight
                getOptionLabel={(option) =>
                  option && option.name && option.dealerId
                    ? `${option.name} (${option.dealerId})`
                    : ''
                }
                style={{ width: 300 }}
                value={selectedDealers || null} // Use null for no selection
                onChange={handleDealerIdsChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a dealer"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <StyledComponent>
            <CustomDataGrid>
              <div
                style={{ height: '75vh', width: '100%' }}
                className={StyledComponent.root}
              >
                <DataGrid
                  paginationModel={{ page, pageSize }}
                  pagination
                  pageSizeOptions={PAGINATION_OPTIONS}
                  onPageSizeChange={handlePageSizeChange}
                  rowCount={rowCount}
                  getRowId={(row) => row._gridId}
                  rows={dataSource}
                  columns={columns}
                  onPaginationModelChange={handlePageChange}
                  // onCellClick={handleCellClick}
                  ref={dataGridRef}
                  className={CustomDataGrid.root}
                  componentsOverrides={{
                    Header: {
                      cell: 'custom-dark-theme--header',
                    },
                    Cell: {
                      cell: 'custom-link-cell',
                    },
                  }}
                />
              </div>
            </CustomDataGrid>
          </StyledComponent>
        )}
      </Grid>
    </Grid>
  );
}

export default SubscriptionsDealerReport;
