import { UPDATE_APP_API_URL, UPDATE_SELECTED_COUNTRY } from '../types';

export const updateApiUrlAction = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_APP_API_URL,
    payload: data,
  });
};

export const updateSelectedCountryAction = (country) => (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_COUNTRY,
    payload: country,
  });
};
