import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import FaqsTagsList from '../components/FaqsTagsList';
import faqsApi from '../api/faqs.api';

function CreateFaqPopup({ open, onClose, onSave }) {
  const [faq, setFaq] = useState({
    label: '',
    value: '',
    isActive: true,
    appRegion: process.env.REACT_APP_DEFAULT_REGION,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaq({ ...faq, [name]: value });
  };

  const handleSave = () => {
    onSave(faq); // Call the function passed from parent to save the FAQ data
    onClose(); // Close the popup after saving
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New FAQ Tag</DialogTitle>
      <DialogContent>
        <TextField
          name="label"
          label="Label"
          fullWidth
          value={faq.label}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          name="value"
          label="Value"
          fullWidth
          value={faq.value}
          onChange={handleInputChange}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            name="isActive"
            value={faq.isActive ? 'true' : 'false'}
            onChange={(event) =>
              setFaq((prev) => ({
                ...prev,
                isActive: event.target.value === 'true',
              }))
            }
          >
            <MenuItem value="true">Active</MenuItem>
            <MenuItem value="false">Inactive</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FaqsTags() {
  const [openPopup, setOpenPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  const handleCreateFaq = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSaveFaq = async (faqData) => {
    try {
      await faqsApi.createFaqsTags(dispatch, faqData);
      setSnackbarMessage('FAQ Tag added successfully');
      setSnackbarOpen(true);
      setOpenPopup(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setSnackbarMessage('Failed to add FAQ Tag');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />

          {
            <>
              <Button
                color="primary"
                onClick={handleCreateFaq}
                variant="contained"
                sx={{
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                  textTransform: 'none',
                }}
              >
                Create FAQ Tag
              </Button>

              <CreateFaqPopup
                open={openPopup}
                onClose={handleClosePopup}
                onSave={handleSaveFaq}
                currentUser
              />
              {/* <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button> */}
            </>
          }

          {/* <FilterModal
            currentFilters={filter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal> */}
        </h5>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="row my-2 ">
        <FaqsTagsList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default FaqsTags;
