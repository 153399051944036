/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { PAGINATION_OPTIONS } from '../constants';
import FilterConfig from './FilterConfig';

function BaseToolBarView() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function BaseListView({
  listTitle,
  columnConfig,
  apiName,
  filterFields,
  viewHeight = '50vh',
}) {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[3]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterString, setFilterString] = useState('');
  const [filterValues, setFilterValues] = useState({});

  const fetchListData = async () => {
    const filterObject = { ...filterValues };
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterObject),
    };

    return apiName(params, dispatch);
  };

  const handlePageChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  function updateColumns() {
    setTableColumns(columnConfig);
  }

  function applyFilter(filterValueObject, filterStringValue) {
    console.log(filterValueObject);
    console.log(filterStringValue);
    setFilterValues(filterValueObject);
    setFilterString(filterStringValue);
  }

  useEffect(() => {
    const loadData = async () => {
      updateColumns();
      const reports = await fetchListData();
      if (reports && reports.data) {
        setDataSource(reports.data);
        setRowCount(reports.totalCount);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    loadData();
  }, [page, pageSize, filterValues]);

  return (
    <div className="container">
      <h4
        className="d-flex align-content-center align-items-center mb-0"
        style={{ textAlign: 'left', padding: '5px' }}
      >
        <div className="flex-grow-1">{listTitle}</div>
        <FilterConfig
          filterFields={filterFields}
          filterValues={filterValues}
          filterString={filterString}
          applyFilter={applyFilter}
        />
      </h4>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pageSizeOptions={PAGINATION_OPTIONS}
            onPaginationModelChange={handlePageChange}
            pagination
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={tableColumns}
            slots={{
              toolbar: BaseToolBarView,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default BaseListView;
