import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
} from '@mui/material';
import { Cancel, OpenInNew } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import JSONViewer from './JSONViewer';
import { formatDateTime } from '../utils/helper';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Activated Offers</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ActivatedOffersList({ viewHeight = '30vh' }) {
  const goToOfferDetails = (detailOfferId) => {
    window.open(`/offers/${detailOfferId}`, '_blank');
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'details') {
      goToOfferDetails(cellParams.row.offerId);
    }
  };

  const columns = [
    {
      headerName: 'Date',
      renderCell: (c) => formatDateTime(c.value),
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'Offer Id',
      field: 'offerId',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Offer" jsonData={params.row} />
            {params.row.offerId}
          </div>
        );
      },
      width: 200,
    },
    {
      headerName: 'Details',
      renderCell: () => (
        <IconButton>
          <OpenInNew />
        </IconButton>
      ),
      field: 'details',
      width: 70,
      disableColumnMenu: true,
    },
    {
      headerName: 'Client',
      field: 'clientType',
      renderCell: (c) => `${c.row.clientType}`,
      width: 90,
      disableColumnMenu: true,
    },
    {
      headerName: 'Contract #',
      field: 'contractNumber',
      renderCell: (c) => `${c.row.contractNumber}`,
      width: 135,
      disableColumnMenu: true,
    },
    {
      headerName: 'Start Date',
      field: 'contractStartDate',
      renderCell: (c) => `${c.row.contractStartDate}`,
      width: 105,
      disableColumnMenu: true,
    },
    {
      headerName: 'Offer Date',
      field: 'offerLetterDate',
      renderCell: (c) => `${c.row.offerLetterDate}`,
      width: 105,
      disableColumnMenu: true,
    },
    {
      headerName: 'End Date',
      field: 'offerEndDate',
      renderCell: (c) => `${c.row.offerEndDate}`,
      width: 105,
      disableColumnMenu: true,
    },
    {
      headerName: 'External Contract #',
      field: 'externalContractNumber',
      renderCell: (c) => `${c.row.externalContractNumber}`,
      width: 300,
      disableColumnMenu: true,
    },
    {
      headerName: 'Bank Transaction Ref',
      field: 'rootTransactionReference',
      renderCell: (c) => `${c.row.rootTransactionReference}`,
      width: 180,
      disableColumnMenu: true,
    },
    {
      headerName: 'Gateway Transaction Id',
      field: 'rootGatewayTransactionId',
      renderCell: (c) => `${c.row.rootGatewayTransactionId}`,
      width: 240,
      disableColumnMenu: true,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contractNumber, setContractNumber] = useState('');
  const [offerId, setOfferId] = useState('');

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const filterValue = {};
    if (offerId) {
      filterValue.offer_id = { eq: offerId };
    }
    if (contractNumber) {
      filterValue.contract_number = { eq: contractNumber };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    setIsLoading(true);

    return subscriptionsApi.getActivatedOffers(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.data.totalItems;
        const currentPageRecords = reports.data.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, contractNumber, offerId]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const resetFilters = () => {
    setDataSource([]);
    setPage(0);
    setContractNumber('');
    setOfferId('');
  };

  const handleFilterOfferId = (event) => {
    setDataSource([]);
    setPage(0);
    setOfferId(event.target.value);
  };

  const handleFilterContractNumber = (event) => {
    setDataSource([]);
    setPage(0);
    setContractNumber(event.target.value);
  };

  return (
    <div className="container">
      <div style={{ padding: 5 }} className="d-flex">
        <div className="flex-grow-1" />
        <div className="d-flex align-items-center">
          <FormControl style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}>
            <InputLabel id="input-offer-id" shrink>
              Offer Id
            </InputLabel>
            <Input
              labelId="input-offer-id"
              id="input-offer"
              value={offerId}
              fullWidth
              onChange={handleFilterOfferId}
            />
          </FormControl>
          <FormControl style={{ margin: '10px 0', width: 'calc(100% - 20px)' }}>
            <InputLabel id="input-contract-number" shrink>
              Contract Number
            </InputLabel>
            <Input
              labelId="input-contract-number"
              id="input-contract"
              value={contractNumber}
              fullWidth
              onChange={handleFilterContractNumber}
            />
          </FormControl>
          {(contractNumber || offerId) && (
            <IconButton onClick={resetFilters}>
              <Cancel color="error" />
            </IconButton>
          )}
        </div>
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            onCellClick={handleCellClick}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default ActivatedOffersList;
