import React from 'react';
import RecurringJourneyLogList from '../components/RecurringJourneyLogList';

function RecurringJourneyLogs() {
  return (
    <div>
      <div className="row my-2 ">
        <RecurringJourneyLogList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default RecurringJourneyLogs;
