/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { Cancel, Close, FilterList } from '@mui/icons-material';

function FilterConfig({
  filterFields,
  filterValues,
  filterString,
  applyFilter,
}) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [filterValueObject, setFilterValueObject] = useState(filterValues);
  const [filterDisplay, setFilterDisplay] = useState('');

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const updateFilterDisplay = () => {
    setFilterDisplay(JSON.stringify(filterValueObject));
  };

  const onApplyFilter = () => {
    applyFilter(filterValueObject);
    updateFilterDisplay();
    handleModalClose();
  };

  const resetFilters = () => {
    setFilterValueObject({});
    setFilterDisplay('');
  };

  const updateFilterObject = (event) => {
    setFilterValueObject({
      ...filterValueObject,
      [event.target.id]: event.target.value,
    });
  };

  return (
    <div>
      {filterDisplay && (
        <span style={{ fontSize: '0.75rem' }}>{filterDisplay}</span>
      )}
      <IconButton onClick={() => setOpenModal(true)}>
        <FilterList color="primary" />
      </IconButton>
      {filterDisplay && (
        <Cancel
          style={{ cursor: 'pointer' }}
          color="error"
          onClick={resetFilters}
        />
      )}
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        fullWidth
        maxWidth="sm"
        style={{
          overflow: 'hidden',
        }}
      >
        <DialogTitle className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            Filters
          </Typography>
          <IconButton onClick={handleModalClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            overflow: 'auto',
          }}
        >
          <div>
            {filterFields.map((field) => (
              <FormControl style={{ margin: '10px 0', width: 'auto' }}>
                {field.type === 'text' && (
                  <>
                    <InputLabel
                      shrink
                      style={{ fontSize: '1.2rem', color: '#000' }}
                    >
                      {field.label}
                    </InputLabel>
                    <Input
                      id={field.value}
                      value={filterValueObject[field.value]}
                      fullWidth
                      onChange={updateFilterObject}
                    />
                  </>
                )}
                {/* {field.type === 'list' && (
                  <>
                    <InputLabel
                      shrink
                      style={{ fontSize: '1.2rem', color: '#000' }}
                    >
                      {field.label}
                    </InputLabel>
                    <Input
                      value={filterValueObject[field.value]}
                      fullWidth
                      onChange={updateFilterObject(field.value)}
                    />
                  </>
                )} */}
              </FormControl>
            ))}
            <div>{filterString}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className="mr-2"
            onClick={onApplyFilter}
          >
            Apply
          </Button>
          <Button variant="outlined" color="primary" onClick={resetFilters}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FilterConfig;
