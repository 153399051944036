import React from 'react';
import moment from 'moment/moment';
import BaseListView from '../components/BaseListView';
import reportApi from '../api/report.api';
import HTMLViewer from '../components/HTMLViewer';

function VCPEmailLogs() {
  const tabConfig = [
    { label: 'All', value: 'ALL' },
    { label: 'Claim Status', value: 'CLAIM_STATUS_UPDATES' },
    { label: 'Support', value: 'SUPPORT_UPDATES' },
    { label: 'Invitation', value: 'Invitation' },
    { label: 'Renewal', value: 'RENEWAL' },
    { label: 'System', value: 'SYSTEM' },
    { label: 'Repair Booking', value: 'REPAIR_BOOKING' },
    { label: 'MFA', value: 'MFA_VERIFICATION' },
  ];

  const filterFields = [
    { label: 'Email', value: 'recipientAddress', type: 'text' },
    { label: 'Category', value: 'category', type: 'list', options: tabConfig },
    { label: 'Type', value: 'type', type: 'text' },
    { label: 'Status', value: 'status', type: 'text' },
    { label: 'Delivery Status', value: 'deliveryStatus', type: 'text' },
    { label: 'Date', value: 'createdAt', type: 'date' },
  ];

  const columnConfig = [
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 160,
      renderCell: (params) =>
        moment(params.value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      field: 'recipientAddress',
      headerName: 'Recipient',
      width: 320,
      renderCell: (params) => params.value,
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      renderCell: (params) => params.value,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => params.value,
    },
    {
      field: 'deliveryStatus',
      headerName: 'Delivery',
      width: 140,
      renderCell: (params) => params.value,
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 350,
      renderCell: (params) => params.value,
    },
    {
      field: 'htmlPath',
      headerName: 'HTML',
      width: 90,
      renderCell: (params) => {
        return (
          <HTMLViewer
            title={params.row.subject}
            htmlPath={params.row.htmlPath}
          />
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />
        </h2>
      </div>
      <div className="row my-2 ">
        <BaseListView
          viewHeight="75vh"
          columnConfig={columnConfig}
          filterFields={filterFields}
          apiName={reportApi.getVCPEmailLogs}
          listTitle="VCP Email Logs"
        />
      </div>
    </div>
  );
}

export default VCPEmailLogs;
