/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { MySQL } from '@codemirror/lang-sql';
import { useDispatch } from 'react-redux';
import { Alert, Button, LinearProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import DynamicDataTable from '@code-based/react-dynamic-data-table';
import subscriptionsApi from '../api/subscriptions.api';
import systemApi from '../api/system.api';

function QueryEditor() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState(
    'SELECT COUNT(offer_id) AS count FROM offer;'
  );
  const [queryData, setQueryData] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChange = React.useCallback((val) => {
    setQuery(val);
  }, []);

  const handleRunClick = async () => {
    setQueryData([]);
    setIsLoading(true);
    const encryptedResponse = await systemApi.encryptText(dispatch, {
      text: query,
    });
    if (encryptedResponse && encryptedResponse.success) {
      const postBody = { input: encryptedResponse.data };
      const response = await subscriptionsApi.postQueryRun(dispatch, postBody);
      setIsLoading(false);
      if (response && response.success) {
        setShowSnackbar(true);
        setMessage('200 OK');
        setQueryData(response.data[0]);
      } else if (response && !response.success) {
        setShowSnackbar(true);
        setMessage(response);
        setQueryData([]);
      }
    } else {
      setIsLoading(false);
      setShowSnackbar(true);
      setMessage(encryptedResponse);
      setQueryData([]);
    }
  };

  const handleClose = () => {
    setMessage('');
    setShowSnackbar(false);
  };

  return (
    <div>
      <div>
        <h4 className="d-flex align-content-center align-items-center mb-0">
          <span className="mr-2">Query Editor</span>
        </h4>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <div className="align-content-left align-items-left">
        <CodeMirror
          style={{ textAlign: 'left' }}
          value={query}
          height="250px"
          extensions={[MySQL]}
          onChange={onChange}
        />
      </div>
      <br />
      {isLoading && (
        <LinearProgress style={{ height: '1rem' }} color="secondary" />
      )}
      {!isLoading && (
        <Button variant="contained" color="secondary" onClick={handleRunClick}>
          Run Query
        </Button>
      )}
      <br />
      <br />
      {!isLoading && <DynamicDataTable rows={queryData} buttons={[]} />}
    </div>
  );
}

export default QueryEditor;
