import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { Close, Download, RemoveRedEyeOutlined } from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';
import subscriptionsApi from '../api/subscriptions.api';
import reportsApi from '../api/report.api';

const dateTimeFormat = process.env.REACT_APP_DATE_TIME_FORMAT;
const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

const LOG_TYPE = {
  All: 'All',
  CustomerEmail: 'CUSTOMER_EMAIL',
  MailingRoomEmail: 'MAILING_ROOM_EMAIL',
  SystemEmail: 'SYSTEM_EMAIL',
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>
        Subscription Email Logs
      </h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

function SubscriptionEmailLogs({ viewHeight = '50vh' }) {
  const [tableColumns, setTableColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[3]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterLogType, setFilterLogType] = useState(LOG_TYPE.All);

  const [emailBody, setEmailBody] = useState(null);
  const [emailSubject, setEmailSubject] = useState(null);
  const [isEmailDataClicked, setIsEmailDataClicked] = useState(false);
  const [isEmailDataLoading, setIsEmailDataLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    let filterValue = {};
    if (filterLogType !== LOG_TYPE.All) {
      filterValue = { type: { eq: filterLogType } };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    // setIsLoading(true);

    return subscriptionsApi.getEmailLogs(dispatch, params);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  function selectTab(type) {
    setDataSource([]);
    setRowCount(0);
    setFilterLogType(type);
  }

  function getTabVariant(type) {
    if (filterLogType === type) {
      return 'contained';
    }
    return 'outlined';
  }

  const fetchEmailData = async (htmlPath) => {
    try {
      setIsEmailDataLoading(true);
      const params = {
        objectKey: htmlPath,
      };
      const response = await reportsApi.getS3ObjectStringContent(
        params,
        dispatch
      );
      if (response.stringContent) {
        setEmailBody(response.stringContent);
      } else {
        setEmailBody('');
      }
    } catch (error) {
      setEmailBody('');
    } finally {
      setIsEmailDataLoading(false);
    }
  };

  const showEmailContent = (subject, htmlPath) => {
    setIsEmailDataClicked(true);
    const fetchDataAndOpenModal = async () => {
      try {
        setEmailSubject(subject);
        await fetchEmailData(htmlPath);
      } finally {
        setOpenModal(true);
      }
    };

    fetchDataAndOpenModal();
  };

  const downloadEmailHtmlFile = async (htmlPath) => {
    try {
      setIsEmailDataLoading(true);
      const params = {
        path: htmlPath,
      };
      const response = await reportsApi.getS3AssetSignedUrl(params, dispatch);
      window.open(response.signedUrl, '_blank');
    } catch (error) {
      setEmailBody('');
    } finally {
      setIsEmailDataLoading(false);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  function updateColumns() {
    const columns = [];
    columns.push({
      headerName: 'Date',
      field: 'createdAt',
      renderCell: (c) => {
        return (
          <div>
            <JSONViewer title={c.row.id} jsonData={c.row} />
            {moment(c.row.createdAt).format(dateTimeFormat)}
          </div>
        );
      },
      headerClassName: 'custom-dark-theme--header',
      width: 210,
    });
    if (filterLogType === LOG_TYPE.All) {
      columns.push({
        headerName: 'Type',
        renderCell: (e) => (e.row.type ? e.row.type : ''),
        field: 'type',
        headerClassName: 'custom-dark-theme--header',
        width: 150,
      });
    }
    columns.push({
      headerName: 'Recipient',
      renderCell: (e) =>
        e.row.recipientAddress ? e.row.recipientAddressrecipientAddress : '',
      field: 'recipientAddress',
      headerClassName: 'custom-dark-theme--header',
      width: 310,
    });
    columns.push({
      headerName: 'Category',
      renderCell: (e) => (e.row.emailCategory ? e.row.emailCategory : ''),
      field: 'emailCategory',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    });
    columns.push({
      headerName: 'Email Type',
      renderCell: (e) => (e.row.emailType ? e.row.emailType : ''),
      field: 'emailType',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    });
    columns.push({
      headerName: 'OfferId',
      renderCell: (e) => (e.row.offerId ? e.row.offerId : ''),
      field: 'offerId',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    });
    columns.push({
      headerName: 'Contract#',
      renderCell: (e) => (e.row.contractNumber ? e.row.contractNumber : ''),
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 170,
    });
    // columns.push({
    //   headerName: 'Subject',
    //   renderCell: (e) => (e.row.subject ? e.row.subject : ''),
    //   field: 'Subject',
    //   headerClassName: 'custom-dark-theme--header',
    //   width: 500,
    // });
    columns.push({
      headerName: 'Metadata',
      renderCell: (c) => {
        return (
          <div>
            <JSONViewer title={c.row.id} jsonData={c.row.metadata} />
          </div>
        );
      },
      field: 'metadata',
      headerClassName: 'custom-dark-theme--header',
      width: 110,
    });
    columns.push({
      headerName: 'Attachments',
      renderCell: (c) => {
        return (
          <div>
            <JSONViewer title={c.row.id} jsonData={c.row.attachments} />
          </div>
        );
      },
      field: 'attachments',
      headerClassName: 'custom-dark-theme--header',
      width: 110,
    });
    columns.push({
      headerName: 'HTML',
      renderCell: (c) => {
        return (
          <>
            <IconButton
              onClick={() => showEmailContent(c.row.subject, c.row.htmlPath)}
            >
              <RemoveRedEyeOutlined />
            </IconButton>
            <IconButton onClick={() => downloadEmailHtmlFile(c.row.htmlPath)}>
              <Download />
            </IconButton>
          </>
        );
      },
      field: 'htmlPath',
      headerClassName: 'custom-dark-theme--header',
      width: 110,
    });
    setTableColumns(columns);
  }

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      updateColumns();
      const reports = await fetchReport();
      if (
        reports &&
        reports.data &&
        reports.data.data &&
        reports.data.data.length > 0
      ) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      } else {
        setDataSource([]); // Clear data if no new data is returned
        setRowCount(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filterLogType]);

  return (
    <div>
      <div style={{ marginBottom: 5 }} className="d-flex">
        <div>
          <ButtonGroup disableElevation variant="outlined" color="primary">
            <Button
              variant={getTabVariant(LOG_TYPE.All)}
              onClick={() => selectTab(LOG_TYPE.All)}
            >
              All
            </Button>
            <Button
              variant={getTabVariant(LOG_TYPE.CustomerEmail)}
              onClick={() => selectTab(LOG_TYPE.CustomerEmail)}
            >
              Customer Email
            </Button>
            {!isUK && (
              <Button
                variant={getTabVariant(LOG_TYPE.MailingRoomEmail)}
                onClick={() => selectTab(LOG_TYPE.MailingRoomEmail)}
              >
                Mailing Room Email
              </Button>
            )}
            <Button
              variant={getTabVariant(LOG_TYPE.SystemEmail)}
              onClick={() => selectTab(LOG_TYPE.SystemEmail)}
            >
              System Email
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={tableColumns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
        {isEmailDataLoading && (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999, // Ensure it's on top of everything
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              Loading Email Data...
            </span>
            <CircularProgress style={{ height: '50px', width: '50px' }} />
          </div>
        )}

        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
          fullWidth={isEmailDataClicked}
          maxWidth={isEmailDataClicked ? 'md' : 'lg'}
          style={{
            overflow: 'hidden', // Hide the dialog scrolling
          }}
        >
          <CustomDialogTitle>
            <Typography variant="h6">
              {isEmailDataClicked ? emailSubject : 'Loading..'}
            </Typography>
            <IconButton
              aria-label="close"
              className={CustomDialogTitle.closeButton}
              onClick={handleModalClose}
            >
              <Close />
            </IconButton>
          </CustomDialogTitle>
          <DialogContent
            style={{
              marginLeft: '20px',
              marginRight: '20px',
              minHeight: isEmailDataClicked ? '500px' : 'auto', // Set your preferred minHeight
              overflow: 'auto',
            }}
          >
            {emailBody && isEmailDataClicked && !isEmailDataLoading && (
              <div
                className={DialogContent.iframeContainer}
                style={{ overflow: 'hidden' }}
              >
                <iframe
                  title="Email Event Body"
                  srcDoc={emailBody}
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '500px',
                    overflow: 'hidden',
                  }}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default SubscriptionEmailLogs;
