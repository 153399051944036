import api from '.';

const SYSTEM_URL = `/admin/system`;

const clearCache = async (dispatch, data) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${SYSTEM_URL}/cache/flush`,
    data
  );
};

const encryptText = async (dispatch, data) => {
  return api.pocketDriveApi(dispatch, 'post', `${SYSTEM_URL}/encrypt`, data);
};

export default {
  clearCache,
  encryptText,
};
