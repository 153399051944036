import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Snackbar,
  Tooltip,
  TextField,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  DeleteForeverSharp,
  Edit as EditIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import faqsApi from '../api/faqs.api';
import { formatDateTime } from '../utils/helper';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>FAQ Tag</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

function FaqsTagsList({ filter, viewHeight = '30vh' }) {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const dispatch = useDispatch();
  const [editFields, setEditFields] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const handleDelete = (row) => {
    setSelectedRow(row);
    setOpenConfirmDelete(true);
  };
  const [faqId, setFaqId] = useState('');

  const handleEditClick = (row) => {
    setFaqId(row._id || '');
    setEditFields({
      label: row.label || '',
      value: row.value || '',
      isActive:
        row.isActive !== undefined && row.isActive !== null
          ? row.isActive
          : false,
    });
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleEditFieldChange = (field, value) => {
    setEditFields((prev) => ({ ...prev, [field]: value }));
  };

  const columns = [
    {
      headerName: 'Created',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 170,
      renderCell: (params) => {
        return <div>{formatDateTime(params.row.createdAt)}</div>;
      },
    },
    {
      headerName: 'Label',
      render: (e) => e.label,
      field: 'label',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Value',
      render: (e) => e.value,
      field: 'value',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Status',
      field: 'isActive',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive ? 'Active' : 'Inactive'}
          sx={{
            backgroundColor: params.row.isActive ? 'green' : 'red',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      ),
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit" arrow>
            <IconButton
              aria-label="edit"
              onClick={() => handleEditClick(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleDelete(params.row)}
              sx={{ color: 'red' }}
            >
              <DeleteForeverSharp />
            </IconButton>
          </Tooltip>
        </div>
      ),
      disableColumnMenu: true,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // const dispatch = useDispatch();

  const handleEditSave = async () => {
    try {
      await faqsApi.updateFaqsTags(dispatch, faqId, editFields);
      setDataSource((prevData) =>
        prevData.map((item) =>
          item._id === faqId ? { ...item, ...editFields } : item
        )
      );
      setSnackbar({
        open: true,
        message: 'Faq Tag updated successfully!',
        severity: 'success',
      });
      setOpenEditModal(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update the Faq Tag.',
        severity: 'error',
      });
    }
  };

  const fetchReport = async () => {
    const params = {
      skip: page * pageSize,
      limit: pageSize,
      appRegion: process.env.REACT_APP_DEFAULT_REGION,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);
    const response = await faqsApi.getFaqsTagsList(dispatch, params);
    return response;
  };

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.totalCount;
        const currentPageRecords = reports.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const confirmDelete = async () => {
    try {
      if (selectedRow) {
        await faqsApi.deleteFaqsTags(dispatch, selectedRow._id);
        setOpenConfirmDelete(false);
        // Update dataSource after deletion
        setDataSource((prev) =>
          prev.filter((item) => item._id !== selectedRow._id)
        );
        setSnackbar({
          open: true,
          message: 'Faq Tag deleted successfully!',
          severity: 'success',
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete the faq.',
        severity: 'error',
      });
    }
  };
  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._gridId}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            // onCellClick={}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      >
        <DialogTitle>Are you sure you want to delete this Faq Tag?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditModal}
        onClose={handleEditModalClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ pb: 2 }}>Edit FAQ Tag</DialogTitle>
        <DialogContent sx={{ px: 3, py: 2 }}>
          <TextField
            label="Label"
            fullWidth
            margin="normal"
            value={editFields.label}
            onChange={(e) => handleEditFieldChange('label', e.target.value)}
            sx={{ mb: 2 }} // Adds spacing between fields
          />
          <TextField
            label="Value"
            fullWidth
            margin="normal"
            value={editFields.value}
            onChange={(e) => handleEditFieldChange('value', e.target.value)}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              value={
                editFields.isActive !== undefined &&
                editFields.isActive !== null
                  ? editFields.isActive
                  : false
              }
              onChange={(e) =>
                handleEditFieldChange('isActive', e.target.value)
              }
            >
              <MenuItem value>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleEditModalClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleEditSave}
            // sx={{
            //   backgroundColor: 'red',
            //   color: 'white',
            //   '&:hover': { backgroundColor: 'darkred' },
            // }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              snackbar.severity === 'error' ? '#ff4c4c' : 'green',
            color: snackbar.severity === 'error' ? '#fefefe' : 'white',
            textAlign: 'center',
          },
        }}
      />
    </div>
  );
}

export default FaqsTagsList;
