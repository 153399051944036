import React from 'react';
import ActivatedOffersList from '../components/ActivatedOffersList';

function ActivatedOffers() {
  return (
    <div>
      <div>
        <h2 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />
        </h2>
      </div>
      <div className="row my-2 ">
        <ActivatedOffersList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default ActivatedOffers;
