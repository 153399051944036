import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Cancel,
  DeleteForeverSharp,
  PlayCircleFilledRounded,
} from '@mui/icons-material';
import moment from 'moment';
import { addDays, addMonths, format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';
import vcpScheduledJobsApi from '../api/vcpScheduledJobs.api';
import FilterModal from './Filter';

const dateTimeFormat = process.env.REACT_APP_DATE_TIME_FORMAT;
const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>VCP Scheduled Jobs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function VCPScheduleJobsList({ viewHeight = '30vh' }) {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmRun, setOpenConfirmRun] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const [filter, setFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [filterScheduledJob, setFilterScheduledJob] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    const params = {
      skip,
      limit,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);
    return vcpScheduledJobsApi.getScheduleJobs(dispatch, params);
  };

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.totalCount;
        const currentPageRecords = reports.data;
        updateDataSource(currentPageRecords);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const scheduledJobOptions = [
    { value: 'SCHEDULED_INVITE_JOB', label: 'SCHEDULED_INVITE_JOB' },
  ];
  if (isUK) {
    scheduledJobOptions.push({
      value: 'CAR_MOT_EXPIRY_REMINDER_JOB',
      label: 'CAR_MOT_EXPIRY_REMINDER_JOB',
    });
    scheduledJobOptions.push({
      value: 'CAR_TAX_EXPIRY_REMINDER_JOB',
      label: 'CAR_TAX_EXPIRY_REMINDER_JOB',
    });
    scheduledJobOptions.push({
      value: 'INVALID_BOUNCED_OFFER_EMAIL_REPORT_GENERATE_JOB',
      label: 'INVALID_BOUNCED_OFFER_EMAIL_REPORT_GENERATE_JOB',
    });
  }

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    let dateDisplay = '';
    if (filterAfterDate || filterBeforeDate) {
      const startDate = filterAfterDate
        ? format(new Date(filterAfterDate), 'yyyy-MM-dd')
        : '1970-01-01';
      const endDate = filterBeforeDate
        ? format(new Date(filterBeforeDate), 'yyyy-MM-dd')
        : format(new Date(), 'yyyy-MM-dd');
      newFilter.created_at = { between: [startDate, endDate] };
      dateDisplay = `Dates: ${startDate} - ${endDate}`;
    }
    if (filterScheduledJob) {
      newFilter.type = filterScheduledJob;
    }
    if (dateDisplay) {
      displays.push(dateDisplay);
    }
    setDataSource([]);
    setRowCount(0);
    setFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && filterAfterDate && filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate, filterAfterDate]);

  useEffect(() => {
    if (applyQuickFilter && filterScheduledJob) {
      setApplyQuickFilter(false);
      saveFilters();
    }
  }, [filterScheduledJob]);

  const setLastDayFilter = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(new Date());
    // setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterBeforeDate(new Date());
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const handleFilterScheduledJob = (event) => {
    setFilterScheduledJob(event.target.value);
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilter({});
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    setFilterScheduledJob('');
  };

  const handleRun = (row) => {
    setSelectedRow(row);
    setOpenConfirmRun(true);
  };

  const handleDelete = (row) => {
    setSelectedRow(row);
    setOpenConfirmDelete(true);
  };
  const columns = [
    {
      headerName: 'Created At',
      field: 'createdAt',
      // headerClassName: 'custom-dark-theme--header',
      renderCell: (c) => {
        return (
          <div>
            <JSONViewer title="Payload" jsonData={c.row.payload} />
            {moment(c.row.createdAt).format(dateTimeFormat)}
          </div>
        );
      },
      width: 220,
    },
    {
      headerName: 'Type',
      render: (e) => e.type,
      field: 'type',
      headerClassName: 'custom-dark-theme--header',
      width: 400,
    },
    {
      headerName: 'Scheduled Time',
      render: (e) => e.scheduledTime,
      field: 'scheduledTime',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 120,
      renderCell: (params) => (
        <div>
          <Tooltip title="Run" arrow>
            <IconButton onClick={() => handleRun(params.row)} color="primary">
              <PlayCircleFilledRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleDelete(params.row)}
              color="secondary"
            >
              <DeleteForeverSharp />
            </IconButton>
          </Tooltip>
        </div>
      ),
      disableColumnMenu: true,
    },
    {
      headerName: 'Metadata',
      renderCell: (e) => {
        if (e.row.type === 'SCHEDULED_INVITE_JOB') {
          try {
            const body = JSON.parse(e.row.payload.body);
            // const decodedMetadata = JSON.parse(atob(e.row.payload));
            return (
              <div>
                <JSONViewer title="Body" jsonData={body} />
                {body && body.holder && body.holder.customerEmail
                  ? `${body.holder.customerEmail}, `
                  : ''}
                {body &&
                body.productWrapper &&
                body.productWrapper.contractNumber
                  ? `${body.productWrapper.contractNumber}, `
                  : ''}
                {body && body.vehicle && body.vehicle.VIN
                  ? `${body.vehicle.VIN}, `
                  : ''}
                {body &&
                body.accountWrapper &&
                body.accountWrapper.accountNumber
                  ? `${body.accountWrapper.accountNumber} `
                  : ''}
                {body && body.accountWrapper && body.accountWrapper.name
                  ? `${body.accountWrapper.name}`
                  : ''}
              </div>
            );
          } catch (error) {
            return <div>{e.row.payload.body}</div>;
          }
        }
        return (
          <div>{e.row.contractNumber ? `${e.row.contractNumber}, ` : ''}</div>
        );
      },
      field: 'metadata',
      headerClassName: 'custom-dark-theme--header',
      width: 650,
    },
  ];

  const confirmRun = async () => {
    try {
      if (selectedRow) {
        const response = await vcpScheduledJobsApi.runScheduledJob(
          dispatch,
          selectedRow._id
        );
        setOpenConfirmRun(false);
        if (response.success) {
          setSnackbar({
            open: true,
            message: 'Job ran successfully!',
            severity: 'success',
          });
        } else {
          // Handle cases where the response does not indicate success
          setSnackbar({
            open: true,
            message: `Job failed to run. ${response.message}.`,
            severity: 'error',
          });
        }
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to run the job.',
        severity: 'error',
      });
    }
  };

  const confirmDelete = async () => {
    try {
      if (selectedRow) {
        const response = await vcpScheduledJobsApi.deleteScheduledJob(
          dispatch,
          selectedRow._id
        );
        setOpenConfirmDelete(false);
        if (response.success) {
          setSnackbar({
            open: true,
            message: 'Job ran successfully!',
            severity: 'success',
          });
        } else {
          // Handle cases where the response does not indicate success
          setSnackbar({
            open: true,
            message: `Job failed to run. ${response.message}.`,
            severity: 'error',
          });
        }
        window.location.reload();
        // Optionally, refresh data here
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete the job.',
        severity: 'error',
      });
    }
  };
  return (
    <div className="container">
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />
          <FormControl style={{ marginBottom: '16px', width: '350px' }}>
            <InputLabel htmlFor="select-scheduled-job-label">
              Scheduled Job Type
            </InputLabel>
            <Select
              labelId="select-scheduled-job-label"
              id="select-scheduled-job"
              value={filterScheduledJob}
              onChange={handleFilterScheduledJob}
              fullWidth
              style={{ overflowY: 'auto' }}
            >
              {scheduledJobOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {filterScheduledJob && (
            <IconButton onClick={resetFilters}>
              <Cancel color="black" />
            </IconButton>
          )}
          {filter && !Object.keys(filter).length && (
            <>
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}

          <FilterModal
            currentFilters={filter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h5>
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
      {/* Run Confirmation Dialog */}
      <Dialog open={openConfirmRun} onClose={() => setOpenConfirmRun(false)}>
        <DialogTitle>Are you sure you want to run this job?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmRun(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRun} color="primary">
            Run
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      >
        <DialogTitle>Are you sure you want to delete this job?</DialogTitle>
        <DialogContent>
          <JSONViewer
            title="Payload"
            jsonData={selectedRow && selectedRow.payload}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              snackbar.severity === 'error' ? '#ff4c4c' : 'green',
            color: snackbar.severity === 'error' ? '#fefefe' : 'white',
            textAlign: 'center',
          },
        }}
      />
    </div>
  );
}

export default VCPScheduleJobsList;
