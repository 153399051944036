import React, { useState } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import FaqsQuesAnswerList from '../components/FaqsQuesAnswerList';
import faqsApi from '../api/faqs.api';

function CreateFaqPopup({ open, onClose, onSave }) {
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const [faq, setFaq] = useState({
    question: '',
    answer: '',
    answerType: 'TEXT',
    appRegion: process.env.REACT_APP_DEFAULT_REGION,
    notes: '',
    authorName: currentUser.name,
    visibility: 'ALL',
    rank: '',
    isActive: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaq({ ...faq, [name]: value });
  };

  const handleSave = () => {
    onSave(faq); // Call the function passed from parent to save the FAQ data
    onClose(); // Close the popup after saving
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New FAQ</DialogTitle>
      <DialogContent>
        <TextField
          name="question"
          label="Question"
          fullWidth
          value={faq.question}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          name="answer"
          label="Answer"
          fullWidth
          value={faq.answer}
          onChange={handleInputChange}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Answer Type</InputLabel>
          <Select
            name="answerType"
            value={faq.answerType}
            onChange={handleInputChange}
            label="Answer Type"
          >
            <MenuItem value="TEXT">TEXT</MenuItem>
            <MenuItem value="HTML">HTML</MenuItem>
            {/* Add other answer types here if needed */}
          </Select>
        </FormControl>
        <TextField
          name="notes"
          label="Notes"
          fullWidth
          value={faq.notes}
          onChange={handleInputChange}
          margin="normal"
          multiline
          rows={4}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Visibility</InputLabel>
          <Select
            name="visibility"
            value={faq.visibility}
            onChange={handleInputChange}
            label="Visibility"
          >
            <MenuItem value="ALL">ALL</MenuItem>
            <MenuItem value="MOBILE">MOBILE</MenuItem>
            <MenuItem value="WEBSITE">WEBSITE</MenuItem>
            {/* Add other visibility options here if needed */}
          </Select>
        </FormControl>
        <TextField
          name="rank"
          label="Rank"
          fullWidth
          value={faq.rank}
          onChange={handleInputChange}
          margin="normal"
          type="number"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            name="isActive"
            value={faq.isActive}
            onChange={(e) =>
              setFaq({ ...faq, isActive: e.target.value === 'true' })
            }
            label="Status"
          >
            <MenuItem value>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FaqsQuesAnswer() {
  const [openPopup, setOpenPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  const handleCreateFaq = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSaveFaq = async (faqData) => {
    try {
      if (faqData.answerType === 'HTML') {
        // eslint-disable-next-line no-param-reassign
        faqData.answer = btoa(faqData.answer); // `btoa` encodes to Base64
      }
      await faqsApi.createFaqs(dispatch, faqData);

      setSnackbarMessage('FAQ added successfully');
      setSnackbarOpen(true);
      setOpenPopup(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setSnackbarMessage('Failed to add FAQ');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div>
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />

          <>
            <Button
              color="primary"
              onClick={handleCreateFaq}
              variant="contained"
              sx={{
                padding: '6px 16px',
                fontSize: '0.875rem',
                textTransform: 'none',
              }}
            >
              Create FAQ
            </Button>

            <CreateFaqPopup
              open={openPopup}
              onClose={handleClosePopup}
              onSave={handleSaveFaq}
              currentUser
            />
          </>
        </h5>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="row my-2 ">
        <FaqsQuesAnswerList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default FaqsQuesAnswer;
